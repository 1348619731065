heading-left .title {
  color: var(--color-white) !important;
}
.logo-testimonial {
  width: 100px;
}
.person-testimonial {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.testimonial-grid-1 {
  display: grid;
  height: 450px;
}
.Testimonialdesig {

  text-align: justify;
}
