.hero-section{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
   
    
}

.button-grp{
    display: flex !important;
    align-items: center;
    gap:40px;
}
@media  screen and (max-width:450px) {
    .button-grp{
        display: flex !important;
        flex-direction: column;
        align-items: center;
        gap:20px;
    }
}
.span_big{
    background-color: #f3ec78;
    background-image: linear-gradient(45deg, #ff6f52, #fcc31e);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
}

/* Scrooll */
